<template>
  <v-container>
    <h1 class="text-left plausibility-title">
      <span v-html="$t('tsm1_plausibility_title')" />
    </h1>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-processing-form ref="search-processing-form" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-local-unit ref="search-local-unit" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-warning ref="search-warning" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('tsm1_plausibility_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('tsm1_plausibility_reset')" /></v-btn>
    <v-btn color="secondary" @click="download"><span v-html="$t('tsm1_plausibility_download')" /></v-btn>
    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      density="compact"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <template
          v-if="
            $privileges.has({
              path: '/processingForms',
              permission: 'write'
            }) ||
            $privileges.has({
              path: '/processingForms',
              permission: 'acceptErrors'
            })
          "
        >
          <router-link :to="{ name: 'processingForms_edit', params: { id: item.id } }" class="tableaction link">
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon></router-link
          >
        </template>
      </template>
      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.dairyId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.dairyIdent }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairyIdent }}
        </template>
      </template>
      <template v-slot:[`item.addressName1`]="{ item }"> {{ item.dairyName }} {{ item.addressName1 }} </template>
      <template v-slot:[`item.correction`]="{ item }">
        {{ item.correction === true ? $t('plausibility_warning_correction_yes') : $t('plausibility_warning_correction_no') }}
      </template>
      <template v-slot:[`item.statusIdent`]="{ item }">
        {{ item['status' + this.$getUpLangKey()] }}
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">{{ $moment(item.validFrom).format('DD.MM.YYYY') }}</template>
      <template v-slot:[`item.validUntil`]="{ item }">{{ $moment(item.validUntil).format('DD.MM.YYYY') }}</template>
      <template v-slot:[`item.totalChecksWarnings`]="{ item }">
        <template
          v-if="
            $privileges.has({
              path: '/processingForms',
              permission: 'write'
            }) ||
            $privileges.has({
              path: '/processingForms',
              permission: 'acceptErrors'
            })
          "
        >
          <router-link
            :to="{
              name: 'processingForms_edit_form_step_one',
              params: { id: item.id }
            }"
            class="tableaction link"
          >
            {{ item.totalChecksWarnings }}</router-link
          >
        </template>
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLocalUnit from '@/components/searchCards/searchLocalUnit.vue'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'
import searchProcessingForm from '@/components/searchCards/searchProcessingForm.vue'
import { useSearchProcessingFormStore } from '@/store/SearchProcessingFormStore'

import searchWarning from '@/components/searchCards/searchWarning.vue'
import { Term } from '@/services/term'
import _ from 'lodash'
import { fileCreator, showError } from '@/services/axios'
import { DTSHeader } from '@/services/BackendService'
import { useSearchWarningStore } from '@/store/SearchWarningStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'processingForm_plausibility_read',
  data() {
    return {
      items: [],
      totalElements: 0,
      loading: false,
      fields: <DTSHeader[]>[
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // MBH-ID
        {
          title: this.$t('tsm1_plausibility_table_header_mbh_id'),
          key: 'dairyIdent',
          sortable: false,
          align: 'left'
        },
        // Name/Firma
        {
          title: this.$t('tsm1_plausibility_table_header_surname'),
          key: 'addressName1',
          sortable: false
        },
        // Vorname/Zusatz
        {
          title: this.$t('tsm1_plausibility_table_header_name'),
          key: 'addressName2',
          sortable: false
        },
        // Status
        {
          title: this.$t('tsm1_plausibility_table_header_status'),
          key: 'status' + this.$getUpLangKey(),
          sortable: false
        },
        //Gesuchs-Periode von
        {
          title: this.$t('tsm1_plausibility_table_header_valid_from'),
          key: 'validFrom',
          sortable: false
        },
        //Bis
        {
          title: this.$t('tsm1_plausibility_table_header_valid_until'),
          key: 'validUntil',
          sortable: false
        },
        //Korrektur
        {
          title: this.$t('tsm1_plausibility_table_header_correction'),
          key: 'correction',
          sortable: false
        },
        //Gesuchs-ID
        {
          title: this.$t('tsm1_plausibility_table_header_ident'),
          key: 'ident',
          align: 'left',
          sortable: false
        },
        //Anzahl Prüfregeln
        {
          title: this.$t('tsm1_plausibility_table_header_number_check_rules'),
          key: 'totalChecks',
          sortable: false
        },
        //davon Fehler
        {
          title: this.$t('tsm1_plausibility_table_header_mistakes'),
          key: 'totalChecksErrors',
          sortable: false
        },
        //davon Hinweise
        {
          title: this.$t('tsm1_plausibility_table_header_warnings'),
          key: 'totalChecksWarnings',
          sortable: false
        }
      ]
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLocalUnitStore(), useSearchLocalUnitStore().items)
      ]
    },
    /* query for axios-calls and route-pushes */
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params(true),
        ...this.getJavaPageOptions({ sort: ['ident,asc'] })
      }
    }
  },
  components: {
    searchDairy,
    searchContact,
    searchLocalUnit,
    searchProcessingForm,
    searchWarning
  },
  methods: {
    params() {
      return {
        ProcessingForm_id: useSearchProcessingFormStore().ProcessingForm_id,
        ProcessingForm_ident: useSearchProcessingFormStore().ProcessingForm_ident,
        ProcessingForm_statusIdMin: useSearchProcessingFormStore().ProcessingForm_statusIdMin,
        ProcessingForm_statusIdMax: useSearchProcessingFormStore().ProcessingForm_statusIdMax,
        ProcessingForm_isCorrection: useSearchProcessingFormStore().ProcessingForm_isCorrection,
        ProcessingForm_validFrom: useSearchProcessingFormStore().ProcessingForm_validFrom,
        ProcessingForm_validUntil: useSearchProcessingFormStore().ProcessingForm_validUntil,
        ProcessingForm_periodId: useSearchProcessingFormStore().ProcessingForm_periodId,
        ProcessingFormValidationWarnings: useSearchWarningStore().ProcessingFormValidationWarnings
      }
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/processingForms/reports/plausibility', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch(() => {
          // route duplicated
          this.load()
        })
    },
    reset() {
      this.$refs['search-dairy'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-local-unit'].reset()
      this.$refs['search-processing-form'].reset()
      this.$refs['search-warning'].reset()
    },
    async download() {
      try {
        const response = await this.axios.get(apiURL + '/processingForms/reports/plausibility', {
          params: this.query,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'warning.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      }
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initialized = true
      if (this.$route.query.size) this.load()
    })
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.plausibility-title {
  margin-bottom: $spacer-xs !important;
}

.button-margin {
  margin-right: $spacer-xs !important;
}
</style>
